<template>
  <div>
    <page-header
      name="Clientes"
    >
      <b-button
        variant="primary"
        @click="newType"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getClients"
    >
      <template v-slot:search>
        <b-row class="p-0 pl-1">
          <b-col
            md="10"
            sm="4"
            class="p-0 pr-1"
          >
            <b-form-input
              v-model="search.search"
              class="filter-input"
              placeholder="Pesquisar por Nome"
              name="nome"
            />
          </b-col>
          <b-col
            sm="2"
            md="2"
          >
            <b-button
              variant="secondary"
              type="button"
              @click="getClients"
            >
              <FeatherIcon icon="SearchIcon" />
              <span
                class="text-nowrap"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-4 text-center">

          <i2-icon-button
            icon="LogInIcon"
            :variant="currentClient === data.item.uuid ? 'outline-success' : 'outline-secondary' "
            class="mr-1"
            @click="setCurrentClient(data.item)"
          />
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentType(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />
        </div>
      </template>
    </Grid>
    <client-form
      :is-active.sync="isActivated"
      :model-uuid="currentType"
      @onModelSave="onClientSave()"
      @onCancel="onCancelEdit()"
    />
  </div>
</template>

<script>

import PageHeader from '@/components/i2/layout/PageHeader.vue'
import { mapActions } from 'vuex'
import ClientForm from '@/views/clients/ClientForm.vue'

export default {
  name: 'ClientList',
  components: {
    ClientForm,
    PageHeader,
  },
  data() {
    return {
      search: {
        search: '',
        type: '',
      },
      user: {
        client: {
          uuid: null,
        },
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-70',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-30',
        },
      ],
      items: [],
      currentClient: null,
      currentType: '',
      typesIndexes: [],
      isActivated: false,
    }
  },
  async mounted() {
    this.user = this.$user.getCurrentUser()
    if (this.user) {
      this.currentClient = this.user.client.uuid
    }
    await this.getClients(1)
  },
  methods: {
    ...mapActions('auth', ['me', 'getMenuTree']),
    setCurrentType(data) {
      this.currentType = data.uuid
      this.isActivated = true
    },
    newType() {
      this.currentType = ''
      this.isActivated = true
    },
    async getClients(page) {
      this.items = await this.$search.get(
        'clients',
        page,
        this.search,
        '',
      )
    },
    onClientSave() {
      this.currentType = ''
      this.isActivated = false
      this.getClients()
    },
    onCancelEdit() {
      this.isActivated = false
      this.currentType = ''
    },
    onDelete(client) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão do cliente.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`clients/${client.uuid}`)
            if (response.error) {
              this.$notify.error(response.error_message)
              return
            }
            this.$notify.success('Cliente removido com sucesso.')
            await this.getClients()
          }
        })
    },
    async setCurrentClient(client) {
      const response = await this.$http.post(`clients/${client.uuid}/select`)
      if (response.error) {
        this.$notify.error(response.error_message)
      }
      this.currentClient = response.client.uuid
      this.$notify.success('Cliente alterado com sucesso. Aguarde enquanto as configurações são carregadas.')
      await this.me()
      location.reload()
    },
  },
}
</script>

<style scoped>

</style>
