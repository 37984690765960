<template>
  <b-sidebar
    id="form-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title=""
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h5 class="mb-0">
          Cadastro de Clientes
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder="informe o nome do cliente"
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.default_filter"
                rules="required"
                label="Filtro Padrão"
                placeholder="informe o filtro padrão"
                name="default_filter"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.database_settings"
                label="Dados de conexão do Banco (SQLServer)"
                placeholder="informe em formato JSON conforme modelo"
                name="database"
                class="mb-0"
              />
              <helper-download href="/assets/examples/database_example.json" />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, email, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import HelperDownload from '@/components/app/HelperDownload.vue'

export default {
  name: 'ClientForm',
  directives: {
    Ripple,
  },
  components: {
    HelperDownload,
    ValidationObserver,
  },
  props: {
    modelUuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      typesIndexes: [],
      model: {
        name: '',
        uuid: null,
        default_filter: '',
        database_settings: '',
      },
      helpDatabase: 'Arquivo de Exemplo<a>',
    }
  },
  watch: {
    modelUuid(data) {
      if (data === '') {
        return
      }
      this.getData(data)
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          if (this.model.uuid) {
            response = await this.$http.put(`clients/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('clients', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Cliente salvo com sucesso.')
          this.$refs.refFormObserver.reset()
          this.model = {
            name: '',
            uuid: null,
            default_filter: null,
            database_settings: '',
          }
          this.$emit('onModelSave', true)
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.model = { ...this.blankUser }
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`clients/${uuid}`)
      if (!response.error) {
        this.model = response
      }
    },
  },
}
</script>

<style scoped>

</style>
